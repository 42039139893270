<div style="position: relative;">

  <div class="boxFooter" style="height: max-content;padding-top:15px;">
    <div class="certificates">
      <div class="divSonFooter">
        <div class="img1">
          <img src="{{urls.imgFolder}}/greatPlaceToWork1.svg">
        </div>
      </div>

      <div class="divSonFooter">
        <div class="img2">
          <img src="{{urls.imgFolder}}/qrCode.svg">
        </div>
      </div>
    </div>
  </div>
</div>









